import api from '@/services/api'

export default {
  namespaced: true,

  state: {
    permissions: [],
  },

  getters: {
    hasPermissions(state) {
      return state.permissions?.length > 0
    },
    hasPermission: (state) => (permissionToCheck) => {
      const permissionFound = state.permissions.find(
        (permission) => permission == permissionToCheck
      )
      return Boolean(permissionFound)
    },
    permissions(state) {
      return state.permissions
    },
  },

  mutations: {
    setPermissions(state, permissions) {
      state.permissions = permissions
    },
  },

  actions: {
    async fetchPermissions({ commit, rootState }) {
      try {
        const seasonCropId = rootState.seasonsCrops.currentSeasonCrop?.id
        const { data } = await api.permissions.get(seasonCropId)
        commit('setPermissions', data)
      } catch ($e) {
        throw new Error('Erro ao buscar permissão')
      }
    },
  },
}
