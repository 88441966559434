export const prepareArrayFilter = (section, values) => {
  const encodedValues = values.map((val) => encodeURIComponent(val))

  if (section === 'gmr') {
    const [start, end] = encodedValues
    return `start_${section}=${start}&end_${section}=${end}`
  }

  return `${section}=${encodedValues.join(',')}`
}

export const prepareSingleFilter = (section, value, isFilterQuery) => {
  const encodeValue = (v) => encodeURIComponent(v)

  if (section === 'sowing_time') {
    return `start_${section}=${encodeValue(value)}&end_${section}=${encodeValue(
      value
    )}`
  }

  const filterValue =
    typeof value === 'string'
      ? value
      : isFilterQuery
      ? value.name
      : value.message_key

  return `${section}=${encodeValue(filterValue)}`
}
