export default {
  Comparison: {
    title: 'Comparar programa',
    back: 'Volver a la planificación',
    header: {
      title: 'Planificación',
      subtitle: 'Comparar programas',
    },
  },
}
