import http, { noAuthHttp } from '@/utils/http'

const auth = {
  login: (email, password) =>
    noAuthHttp.post('/digifarmz-login', { email, password }),
  recoverPassword: (email) => noAuthHttp.post('/recover-password', { email }),
  selectAccount: (account_id) => http.post('/select-account', { account_id }),
  logout: () => http.post('/logout'),
  refreshToken: () => http.post('/token-refresh'),
  trocarSenha: (password_old, password, password_confirmation) =>
    http.post('/change-password', {
      password_old: password_old,
      password: password,
      password_confirmation: password_confirmation,
    }),
}

export default auth
