import catalog_en from './catalog_en'

export default {
  Catalog: {
    Home: {
      title: 'Catalog',
      subtitle: 'Planning',
    },
    ComingSoon: {
      label: 'Coming Soon',
      title: 'Catalog of your agricultural inputs',
      description:
        'We simplify access to vital data for agronomy through a complete catalog of the main agricultural inputs, product leaflets and information for producer decision-making, saving time and effort',
      Buttons: {
        like: 'Like',
        dislike: 'Dislike',
      },
      Toast: {
        thanksForFeedback: 'Thanks for your feedback!',
      },
      Messages: {
        feedbackCongratulations: 'Thanks for your feedback! 🎉',
      },
    },
    Categories: {
      fungicide: 'Fungicide',
      nematicide: 'Nematicide',
      variety: 'Varieties',
    },
    FilterBar: {
      name: 'Product name',
      order: 'Order',
      filter: 'Filter',
      Crop: {
        soybean: 'Soy',
        wheat: 'Wheat',
      },
    },
    asset_owner: 'Company',
    owner_search: 'Search company name',
    target: 'Target',
    to: 'to',
    chemical_group: 'Chemical group',
    active_ingredient: 'Active ingredient',
    crop_cycle: 'Approximate cycle',
    gmr: 'RM',
    sowing_time: 'Sowing season/time',
    no_selected: 'Select an item to view detailed information',
    general_information: 'General information',
    action_mode: 'Action mode',
    agronomic_category: 'Agronomical category',
    operation_type: 'Uso',
    active_ingredient_info: 'Active ingredient (concentration)',
    measure: 'Measure',
    formulation: 'Formulation',
    information_variety: 'Information | geolocated',
    habit: 'Growth Habit',
    approximate_cycle: 'Approximate cycle',
    suggested_population: 'Suggested population',
    ogm: 'Genetically Modified Organism',
    population_suggestion: 'thousand plants/ha',
    dosis_target: 'Doses per target air part',
    dosis_by_target_values: '{0}L to {1}L',
    Options: {
      Sort: {
        name_a_z: 'Name (A - Z)',
        name_z_a: 'Name (Z - A)',
        company_a_z: 'Company (A - Z)',
        company_z_a: 'Company (Z - A)',
        gmr_less_greater: 'RM (Greater)',
        gmr_greater_less: 'RM (Less)',
      },
      soybean_asian_soybean_rust: 'Soybean rust',
      soybean_anthracnosis: 'Anthracnose',
      soybean_target_spot: 'Target spot',
      soybean_powdery_mildew: 'Powdery mildew',
      soybean_cercospora_blight: 'Cercospora blight',
      soybean_septoria_leaf_spot: 'Septoria spot',
      soybean_aerial_web_blight: 'Aerial web blight',
      soybean_white_mold: 'White mold',
      soybean_frogeye_leaf_spot: 'Frogeye leaf spot',
      soybean_diaporthe: 'Diaporthe',
      wheat_leaf_rust: 'Wheat leaf rust',
      wheat_powdery_mildew: 'Wheat powdery mildew',
      wheat_yellow_leaf_spot: 'Yellow leaf spot',
      wheat_fusarium_head_blight: 'Gibberella ear rot',
      wheat_blast: 'Wheat blast',
      ...catalog_en,
    },
  },
}
