const Module = () =>
  import(
    /* webpackChunkName: "catalog-module" */ '@/modules/catalog/Module.vue'
  )
const Soon = () =>
  import(
    /* webpackChunkName: "catalog-module" */ '@/modules/catalog/views/Soon.vue'
  )
const CatalogHome = () =>
  import(
    /* webpackChunkName: "catalog-module" */ '@/modules/catalog/views/CatalogHome.vue'
  )

const routes = [
  {
    path: '/catalog',
    component: Module,
    children: [
      {
        path: 'soon',
        component: Soon,
      },
      {
        path: '/',
        component: CatalogHome,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
]

export default routes
