import MainApp from './MainApp.vue'
import VueRouter, { router } from './router'
import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

import Zendesk from '@dansmaculotte/vue-zendesk'

import Api from '@/services/api'
import featureToggle from '@/utils/featureToggle'

import loadPrototypes, { errorsPrototypes } from '@/utils/prototypes'

import Vuetify, { instantiateVuetify } from '@/plugins/vuetify'

require('@/plugins/fontawesome')

import i18n, {
  currencyFormatter,
  currentCurrency,
  currentLanguage,
  LANG,
} from '@/language'
import { unitMeasure } from '@/language/unitMeasure'

import moment from 'moment-timezone'

require('@/utils/directives')

import { TippyComponent } from 'vue-tippy'
import 'tippy.js/themes/light.css'
Vue.component('Tippy', TippyComponent)

Vue.prototype.moment = moment

import * as VueGoogleMaps from 'vue2-google-maps'

import 'leaflet/dist/leaflet.css'
import { Icon } from 'leaflet'

import { Coordinate, GeometryFactory } from 'jsts/org/locationtech/jts/geom.js'
import { GeometryGraph } from 'jsts/org/locationtech/jts/geomgraph.js'
import { valid } from 'jsts/org/locationtech/jts/operation.js'

import { isDevMode } from '@/utils/envConfigs'

import store from './store'

Vue.prototype.$jsts = {
  Coordinate: Coordinate,
  GeometryFactory: GeometryFactory,
  GeometryGraph: GeometryGraph,
  valid: valid,
}

delete Icon.Default.prototype._getIconUrl

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    libraries: 'places,drawing,geometry',
    language: currentLanguage(),
  },
})

if (
  process.env.VUE_APP_ZENDESK_ENABLE == 'true' &&
  process.env.VUE_APP_ZENDESK_ID
) {
  Vue.use(Zendesk, {
    key: process.env.VUE_APP_ZENDESK_ID,
    disabled: false,
    hideOnLoad: false,
    settings: {
      webWidget: {
        color: {
          theme: '#78a300',
        },
      },
    },
  })
}

import VueTour from 'vue-tour'

require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)

import VueMask from 'v-mask'
Vue.use(VueMask, {
  placeholders: {
    D: /\d*/, // define new placeholder
  },
})

import money from 'v-money'
Vue.use(money)

loadPrototypes()

// API Global
Vue.prototype.$api = Api

Vue.prototype.$toggle = featureToggle
Vue.prototype.$PermissionError = errorsPrototypes.permissionError

// Idiomas Global

Vue.prototype.$idiomas = [
  { sigla: LANG.PT, nome: 'Português' },
  { sigla: LANG.ES, nome: 'Espanhol' },
  { sigla: LANG.EN, nome: 'Inglês' },
]

Vue.prototype.$unitMeasures = unitMeasure
Vue.prototype.$currentLanguage = currentLanguage
Vue.prototype.$currency = currencyFormatter
Vue.prototype.$currentCurrency = currentCurrency

Vue.use(VueRouter)

import permissionMap from './modules/auth/helper/permissionsMap'
Vue.prototype.$p = permissionMap

Vue.use(Vuetify)
const vuetify = instantiateVuetify({ i18n })

const sentryEnv = process.env.VUE_APP_SENTRY_ENV
Sentry.init({
  Vue: Vue,
  dsn: process.env.VUE_APP_SENTRY,
  integrations: [new Integrations.BrowserTracing()],
  routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  environment: sentryEnv,
  tracesSampleRate: process.env.VUE_APP_SENTRY_SAMPLE_TRACES,
})

Vue.config.productionTip = false

import { version } from '../package.json'

Vue.prototype.$version = version

Vue.config.errorHandler = (err) => {
  if (isDevMode) {
    console.error('You should not ignore it: ', err)
  }
}

if (isDevMode) {
  Vue.config.devtools = true
}

import OneSignalVue from 'onesignal-vue'
Vue.use(OneSignalVue)

import { mockServer } from '../mirage.config.js'
import { createServer } from 'miragejs'
if (isDevMode && process.env.VUE_APP_MOCK_MIRAGE) {
  createServer(mockServer)
}

new Vue({
  i18n,
  router,
  vuetify,
  store,
  render: (h) => h(MainApp),
  beforeMount() {
    if (!isDevMode) {
      this.$OneSignal.init({ appId: process.env.VUE_APP_ONESIGNAL_APP_ID })
    }
  },
}).$mount('#app')
