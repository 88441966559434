const catalogFungicides = [
  {
    country: 'Brasil',
    crop: 'crop.soybean',
    pesticide: 'Abacus HC',
    agronomic_category: 'control_method.chemical',
    asset_owner: 'Basf',
    pesticide_unit: 'product_unit_measure.l',
    formulation: 'pesticide_formulation.concentrated_suspension_sc',
    pesticide_id: 1,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.strobilurin',
        mode_of_action: 'pesticide_mode_of_action.site_specific_fungicide',
        active_ingredient: 'active_ingredient.pyraclostrobin',
        active_ingredient_concentration: 260,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
      {
        chemical_group: 'chemical_group.triazole',
        mode_of_action: 'pesticide_mode_of_action.site_specific_fungicide',
        active_ingredient: 'active_ingredient.epoxiconazole',
        active_ingredient_concentration: 160,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_cercospora_blight',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 0.25,
          dosis_max: 0.3,
        },
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_powdery_mildew',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 0.25,
          dosis_max: 0.3,
        },
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_septoria_leaf_spot',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 0.25,
          dosis_max: 0.3,
        },
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_target_spot',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 0.25,
          dosis_max: 0.3,
        },
      ],
    ],
  },
  {
    country: 'Brasil',
    crop: 'crop.soybean',
    pesticide: 'Absoluto 500 SC',
    agronomic_category: 'control_method.chemical',
    asset_owner: 'Iharabras',
    pesticide_unit: 'product_unit_measure.l',
    formulation: 'pesticide_formulation.concentrated_suspension_sc',
    pesticide_id: 530,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.isophthalonitrile',
        mode_of_action: 'pesticide_mode_of_action.organic_multi_site_fungicide',
        active_ingredient: 'active_ingredient.chlorothalonil',
        active_ingredient_concentration: 500,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_septoria_leaf_spot',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 2,
          dosis_max: 3,
        },
      ],
    ],
  },
  {
    country: 'Brasil',
    crop: 'crop.soybean',
    pesticide: 'Absoluto FIX',
    agronomic_category: 'control_method.chemical',
    asset_owner: 'Iharabras',
    pesticide_unit: 'product_unit_measure.l',
    formulation: 'pesticide_formulation.concentrated_suspension_sc',
    pesticide_id: 728,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.isophthalonitrile',
        mode_of_action: 'pesticide_mode_of_action.organic_multi_site_fungicide',
        active_ingredient: 'active_ingredient.chlorothalonil',
        active_ingredient_concentration: 720,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_asian_soybean_rust',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 1.4,
          dosis_max: 1.8,
        },
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_septoria_leaf_spot',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 1.5,
          dosis_max: 2,
        },
      ],
    ],
  },
  {
    country: 'Brasil',
    crop: 'crop.soybean',
    pesticide: 'Across',
    agronomic_category: 'control_method.chemical',
    asset_owner: 'Adama',
    pesticide_unit: 'product_unit_measure.l',
    formulation: 'pesticide_formulation.concentrated_suspension_sc',
    pesticide_id: 729,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.isophthalonitrile',
        mode_of_action: 'pesticide_mode_of_action.organic_multi_site_fungicide',
        active_ingredient: 'active_ingredient.chlorothalonil',
        active_ingredient_concentration: 500,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
      {
        chemical_group: 'chemical_group.strobilurin',
        mode_of_action: 'pesticide_mode_of_action.site_specific_fungicide',
        active_ingredient: 'active_ingredient.azoxystrobin',
        active_ingredient_concentration: 40,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
      {
        chemical_group: 'chemical_group.triazole',
        mode_of_action: 'pesticide_mode_of_action.site_specific_fungicide',
        active_ingredient: 'active_ingredient.difenoconazole',
        active_ingredient_concentration: 40,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_anthracnosis',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 1.75,
          dosis_max: 2,
        },
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_asian_soybean_rust',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 2,
          dosis_max: 2.5,
        },
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_cercospora_blight',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 1.5,
          dosis_max: 2,
        },
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_powdery_mildew',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 1.75,
          dosis_max: 2,
        },
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_septoria_leaf_spot',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 1.5,
          dosis_max: 2,
        },
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_target_spot',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 2,
          dosis_max: 2.5,
        },
      ],
    ],
  },
  {
    country: 'Brasil',
    crop: 'crop.soybean',
    pesticide: 'Adante Xtra',
    agronomic_category: 'control_method.chemical',
    asset_owner: 'Syngenta',
    pesticide_unit: 'product_unit_measure.l',
    formulation: 'pesticide_formulation.concentrated_suspension_sc',
    pesticide_id: 582,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.others',
        mode_of_action: 'pesticide_mode_of_action.unknown',
        active_ingredient: 'active_ingredient.others',
        active_ingredient_concentration: 182,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
      {
        chemical_group: 'chemical_group.strobilurin',
        mode_of_action: 'pesticide_mode_of_action.site_specific_fungicide',
        active_ingredient: 'active_ingredient.azoxystrobin',
        active_ingredient_concentration: 242,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
      {
        chemical_group: 'chemical_group.triazole',
        mode_of_action: 'pesticide_mode_of_action.site_specific_fungicide',
        active_ingredient: 'active_ingredient.cyproconazole',
        active_ingredient_concentration: 96,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_asian_soybean_rust',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 0.25,
          dosis_max: 0.33,
        },
      ],
    ],
  },
  {
    country: 'Brasil',
    crop: 'crop.soybean',
    pesticide: 'Aderis',
    agronomic_category: 'control_method.chemical',
    asset_owner: 'Ouro Fino Qu\u00edmica',
    pesticide_unit: 'product_unit_measure.l',
    formulation: 'pesticide_formulation.concentrated_suspension_sc',
    pesticide_id: 571,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.isophthalonitrile',
        mode_of_action: 'pesticide_mode_of_action.organic_multi_site_fungicide',
        active_ingredient: 'active_ingredient.chlorothalonil',
        active_ingredient_concentration: 500,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_asian_soybean_rust',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 2,
          dosis_max: 3,
        },
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_septoria_leaf_spot',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 2,
          dosis_max: 3,
        },
      ],
    ],
  },
  {
    country: 'Brasil',
    crop: 'crop.soybean',
    pesticide: 'Agata',
    agronomic_category: 'control_method.chemical',
    asset_owner: 'ISK Biosciences',
    pesticide_unit: 'product_unit_measure.l',
    formulation: 'pesticide_formulation.concentrated_suspension_sc',
    pesticide_id: 816,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.phenylpyridinylamine',
        mode_of_action: 'pesticide_mode_of_action.site_specific_fungicide',
        active_ingredient: 'active_ingredient.fluazinam',
        active_ingredient_concentration: 500,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_white_mold',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 0.75,
          dosis_max: 1,
        },
      ],
    ],
  },
  {
    country: 'Brasil',
    crop: 'crop.soybean',
    pesticide: 'Agroben 500',
    agronomic_category: 'control_method.chemical',
    asset_owner: 'Agroimport do Brasil',
    pesticide_unit: 'product_unit_measure.l',
    formulation: 'pesticide_formulation.concentrated_suspension_sc',
    pesticide_id: 12,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.benzimidazole',
        mode_of_action: 'pesticide_mode_of_action.site_specific_fungicide',
        active_ingredient: 'active_ingredient.carbendazim',
        active_ingredient_concentration: 500,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_cercospora_blight',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 0.5,
          dosis_max: 0.5,
        },
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_powdery_mildew',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 0.5,
          dosis_max: 0.5,
        },
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_septoria_leaf_spot',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 0.5,
          dosis_max: 0.5,
        },
      ],
    ],
  },
  {
    country: 'Brasil',
    crop: 'crop.soybean',
    pesticide: 'Agrotop',
    agronomic_category: 'control_method.chemical',
    asset_owner: 'Pilarquim BR Comercial',
    pesticide_unit: 'product_unit_measure.l',
    formulation: 'pesticide_formulation.emulsifiable_concentrate_ec',
    pesticide_id: 940,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.triazole',
        mode_of_action: 'pesticide_mode_of_action.site_specific_fungicide',
        active_ingredient: 'active_ingredient.difenoconazole',
        active_ingredient_concentration: 250,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_asian_soybean_rust',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 0.15,
          dosis_max: 0.2,
        },
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_target_spot',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 0.15,
          dosis_max: 0.2,
        },
      ],
    ],
  },
  {
    country: 'Brasil',
    crop: 'crop.soybean',
    pesticide: 'AgTecmmon',
    agronomic_category: 'control_method.biological',
    asset_owner: 'Massen Produtos Biologicos',
    pesticide_unit: 'product_unit_measure.l',
    formulation: 'pesticide_formulation.concentrated_suspension_sc',
    pesticide_id: 862,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.biological',
        mode_of_action: 'pesticide_mode_of_action.unknown',
        active_ingredient: 'active_ingredient.bacillus_amyloliquefaciens',
        active_ingredient_concentration: 473.5,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_anthracnosis',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 0.75,
          dosis_max: 1,
        },
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_target_spot',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 0.5,
          dosis_max: 1,
        },
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_white_mold',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 0.75,
          dosis_max: 1,
        },
      ],
    ],
  },
  {
    country: 'Brasil',
    crop: 'crop.soybean',
    pesticide: 'Airone Inox',
    agronomic_category: 'control_method.chemical',
    asset_owner: 'Isagro Brasil Comercio de Produtos Agroqu\u00edmicos',
    pesticide_unit: 'product_unit_measure.l',
    formulation: 'pesticide_formulation.concentrated_suspension_sc',
    pesticide_id: 581,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.inorganic',
        mode_of_action:
          'pesticide_mode_of_action.inorganic_multi_site_fungicide',
        active_ingredient: 'active_ingredient.copper_oxychloride',
        active_ingredient_concentration: 239.4,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
      {
        chemical_group: 'chemical_group.others',
        mode_of_action: 'pesticide_mode_of_action.unknown',
        active_ingredient: 'active_ingredient.others',
        active_ingredient_concentration: 223,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_asian_soybean_rust',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 2,
          dosis_max: 4,
        },
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_cercospora_blight',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 1,
          dosis_max: 3,
        },
      ],
    ],
  },
  {
    country: 'Brasil',
    crop: 'crop.soybean',
    pesticide: 'Airone Scudo',
    agronomic_category: 'control_method.chemical',
    asset_owner: 'Isagro Brasil Comercio de Produtos Agroqu\u00edmicos',
    pesticide_unit: 'product_unit_measure.l',
    formulation: 'pesticide_formulation.concentrated_suspension_sc',
    pesticide_id: 675,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.inorganic',
        mode_of_action:
          'pesticide_mode_of_action.inorganic_multi_site_fungicide',
        active_ingredient: 'active_ingredient.copper_oxychloride',
        active_ingredient_concentration: 239.4,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
      {
        chemical_group: 'chemical_group.others',
        mode_of_action: 'pesticide_mode_of_action.unknown',
        active_ingredient: 'active_ingredient.others',
        active_ingredient_concentration: 223,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_asian_soybean_rust',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 2,
          dosis_max: 4,
        },
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_cercospora_blight',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 1,
          dosis_max: 3,
        },
      ],
    ],
  },
  {
    country: 'Brasil',
    crop: 'crop.soybean',
    pesticide: 'Alade',
    agronomic_category: 'control_method.chemical',
    asset_owner: 'Syngenta',
    pesticide_unit: 'product_unit_measure.l',
    formulation: 'pesticide_formulation.emulsifiable_concentrate_ec',
    pesticide_id: 730,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.carboxamide',
        mode_of_action: 'pesticide_mode_of_action.site_specific_fungicide',
        active_ingredient: 'active_ingredient.benzovindiflupyr',
        active_ingredient_concentration: 60,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
      {
        chemical_group: 'chemical_group.triazole',
        mode_of_action: 'pesticide_mode_of_action.site_specific_fungicide',
        active_ingredient: 'active_ingredient.cyproconazole',
        active_ingredient_concentration: 90,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
      {
        chemical_group: 'chemical_group.triazole',
        mode_of_action: 'pesticide_mode_of_action.site_specific_fungicide',
        active_ingredient: 'active_ingredient.difenoconazole',
        active_ingredient_concentration: 150,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_anthracnosis',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 0.35,
          dosis_max: 0.75,
        },
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_asian_soybean_rust',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 0.35,
          dosis_max: 0.75,
        },
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_cercospora_blight',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 0.35,
          dosis_max: 0.75,
        },
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_powdery_mildew',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 0.35,
          dosis_max: 0.75,
        },
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_septoria_leaf_spot',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 0.35,
          dosis_max: 0.75,
        },
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_target_spot',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 0.35,
          dosis_max: 0.75,
        },
      ],
    ],
  },
  {
    country: 'Brasil',
    crop: 'crop.soybean',
    pesticide: '\u00c1libi',
    agronomic_category: 'control_method.chemical',
    asset_owner: 'Syngenta',
    pesticide_unit: 'product_unit_measure.l',
    formulation: 'pesticide_formulation.concentrated_suspension_sc',
    pesticide_id: 533,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.strobilurin',
        mode_of_action: 'pesticide_mode_of_action.site_specific_fungicide',
        active_ingredient: 'active_ingredient.azoxystrobin',
        active_ingredient_concentration: 200,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
      {
        chemical_group: 'chemical_group.triazole',
        mode_of_action: 'pesticide_mode_of_action.site_specific_fungicide',
        active_ingredient: 'active_ingredient.difenoconazole',
        active_ingredient_concentration: 125,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_cercospora_blight',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 0.3,
          dosis_max: 0.3,
        },
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_powdery_mildew',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 0.3,
          dosis_max: 0.3,
        },
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_septoria_leaf_spot',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 0.3,
          dosis_max: 0.3,
        },
      ],
    ],
  },
  {
    country: 'Brasil',
    crop: 'crop.soybean',
    pesticide: 'Almada',
    agronomic_category: 'control_method.chemical',
    asset_owner: 'Adama',
    pesticide_unit: 'product_unit_measure.l',
    formulation:
      'pesticide_formulation.oil_dispersion_or_oil_concentrated_suspension_od',
    pesticide_id: 999,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.carboxamide',
        mode_of_action: 'pesticide_mode_of_action.site_specific_fungicide',
        active_ingredient: 'active_ingredient.fluxapyroxad',
        active_ingredient_concentration: 22.5,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
      {
        chemical_group: 'chemical_group.dithiocarbamate',
        mode_of_action: 'pesticide_mode_of_action.organic_multi_site_fungicide',
        active_ingredient: 'active_ingredient.mancozeb',
        active_ingredient_concentration: 440,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
      {
        chemical_group: 'chemical_group.triazolinthione',
        mode_of_action: 'pesticide_mode_of_action.site_specific_fungicide',
        active_ingredient: 'active_ingredient.prothioconazole',
        active_ingredient_concentration: 31.5,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_anthracnosis',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 2.25,
          dosis_max: 2.25,
        },
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_asian_soybean_rust',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 2,
          dosis_max: 2,
        },
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_cercospora_blight',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 2.25,
          dosis_max: 2.25,
        },
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_powdery_mildew',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 2.25,
          dosis_max: 2.25,
        },
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_septoria_leaf_spot',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 2.25,
          dosis_max: 2.25,
        },
        {
          operation_type: 'operation_type.aerial_spray',
          target: 'soybean_target_spot',
          dosis_unit: 'product_unit_measure.l',
          dosis_min: 2.25,
          dosis_max: 2.25,
        },
      ],
    ],
  },
]

const catalogNematicides = [
  {
    country: 'Brasil',
    pesticide: 'Abamectin Nortox 400 WG',
    agronomic_category: 'control_method.chemical',
    asset_owner: 'Nortox',
    pesticide_unit: 'product_unit_measure.kg',
    formulation: 'pesticide_formulation.dispersible_granules_wg',
    pesticide_id: 1061,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.avermectin',
        mode_of_action:
          'pesticide_mode_of_action.contact_and_ingestion_insecticide',
        active_ingredient: 'active_ingredient.abamectin',
        active_ingredient_concentration: 400,
        active_ingredient_unit: 'product_unit_measure.g_kg',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.in_furrow',
          dosis_unit: 'product_unit_measure.kg_ha',
          dosis_min: 1.5,
          dosis_max: 1.5,
        },
      ],
    ],
  },
  {
    country: 'Brasil',
    pesticide: 'Abamex',
    agronomic_category: 'control_method.chemical',
    asset_owner: 'Sumitomo Chemical',
    pesticide_unit: 'product_unit_measure.l',
    formulation: 'pesticide_formulation.emulsifiable_concentrate_ec',
    pesticide_id: 1070,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.avermectin',
        mode_of_action:
          'pesticide_mode_of_action.contact_and_ingestion_insecticide',
        active_ingredient: 'active_ingredient.abamectin',
        active_ingredient_concentration: 18,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.seed_treatment',
          dosis_unit: 'product_unit_measure.l_100_kg_de_semente',
          dosis_min: 0.1,
          dosis_max: 0.125,
        },
      ],
    ],
  },
  {
    country: 'Brasil',
    pesticide: 'Andril Prime',
    agronomic_category: 'control_method.biological',
    asset_owner: 'Basf',
    pesticide_unit: 'product_unit_measure.l',
    formulation:
      'pesticide_formulation.concentrated_suspension_for_seed_treatment_fs',
    pesticide_id: 1043,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.biological',
        mode_of_action: 'pesticide_mode_of_action.unknown',
        active_ingredient: 'active_ingredient.bacillus_firmus',
        active_ingredient_concentration: 240,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.seed_treatment',
          dosis_unit: 'product_unit_measure.l_100_kg_de_semente',
          dosis_min: 0.07,
          dosis_max: 0.56,
        },
      ],
    ],
  },
  {
    country: 'Brasil',
    pesticide: 'Atialy',
    agronomic_category: 'control_method.biological',
    asset_owner: 'Agrobiol\u00f3gica Sustentabilidade',
    pesticide_unit: 'product_unit_measure.l',
    formulation: 'pesticide_formulation.soluble_concentrate_sl',
    pesticide_id: 1047,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.biological',
        mode_of_action: 'pesticide_mode_of_action.unknown',
        active_ingredient: 'active_ingredient.paecilomyces_lilacinus',
        active_ingredient_concentration: 7,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.in_furrow',
          dosis_unit: 'product_unit_measure.l_ha',
          dosis_min: 1,
          dosis_max: 4,
        },
      ],
    ],
  },
  {
    country: 'Brasil',
    pesticide: 'Augory',
    agronomic_category: 'control_method.chemical',
    asset_owner: 'Syngenta',
    pesticide_unit: 'product_unit_measure.l',
    formulation:
      'pesticide_formulation.concentrated_suspension_for_seed_treatment_fs',
    pesticide_id: 1080,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.avermectin',
        mode_of_action:
          'pesticide_mode_of_action.contact_and_ingestion_insecticide',
        active_ingredient: 'active_ingredient.abamectin',
        active_ingredient_concentration: 500,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.seed_treatment',
          dosis_unit: 'product_unit_measure.l_100_kg_de_semente',
          dosis_min: 0.1,
          dosis_max: 0.125,
        },
      ],
    ],
  },
  {
    country: 'Brasil',
    pesticide: 'Aveo EZ',
    agronomic_category: 'control_method.biological',
    asset_owner: 'Sumitomo Chemical',
    pesticide_unit: 'product_unit_measure.l',
    formulation:
      'pesticide_formulation.concentrated_suspension_for_seed_treatment_fs',
    pesticide_id: 1033,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.biological',
        mode_of_action: 'pesticide_mode_of_action.unknown',
        active_ingredient: 'active_ingredient.bacillus_amyloliquefaciens',
        active_ingredient_concentration: 270,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.seed_treatment',
          dosis_unit: 'product_unit_measure.ml_ha',
          dosis_min: 3.6,
          dosis_max: 10,
        },
      ],
    ],
  },
  {
    country: 'Brasil',
    pesticide: 'Avicta 500 FS',
    agronomic_category: 'control_method.chemical',
    asset_owner: 'Syngenta',
    pesticide_unit: 'product_unit_measure.l',
    formulation:
      'pesticide_formulation.concentrated_suspension_for_seed_treatment_fs',
    pesticide_id: 1055,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.avermectin',
        mode_of_action:
          'pesticide_mode_of_action.contact_and_ingestion_insecticide',
        active_ingredient: 'active_ingredient.abamectin',
        active_ingredient_concentration: 500,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.seed_treatment',
          dosis_unit: 'product_unit_measure.l_100_kg_de_semente',
          dosis_min: 0.1,
          dosis_max: 0.125,
        },
      ],
    ],
  },
  {
    country: 'Brasil',
    pesticide: 'Avicta 500 FS Pro',
    agronomic_category: 'control_method.chemical',
    asset_owner: 'Syngenta',
    pesticide_unit: 'product_unit_measure.l',
    formulation:
      'pesticide_formulation.concentrated_suspension_for_seed_treatment_fs',
    pesticide_id: 1052,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.avermectin',
        mode_of_action:
          'pesticide_mode_of_action.contact_and_ingestion_insecticide',
        active_ingredient: 'active_ingredient.abamectin',
        active_ingredient_concentration: 500,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.seed_treatment',
          dosis_unit: 'product_unit_measure.l_100_kg_de_semente',
          dosis_min: 0.1,
          dosis_max: 0.125,
        },
      ],
    ],
  },
  {
    country: 'Brasil',
    pesticide: 'Azir',
    agronomic_category: 'control_method.chemical',
    asset_owner: 'Adama',
    pesticide_unit: 'product_unit_measure.l',
    formulation:
      'pesticide_formulation.encapsulated_suspension_for_seed_treatment_cf',
    pesticide_id: 1109,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.fluoroalkenyl',
        mode_of_action: 'pesticide_mode_of_action.systemic_nematicide',
        active_ingredient: 'active_ingredient.fluensulfona',
        active_ingredient_concentration: 200,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.seed_treatment',
          dosis_unit: 'product_unit_measure.l_100_kg_de_semente',
          dosis_min: 0.125,
          dosis_max: 0.25,
        },
      ],
    ],
  },
  {
    country: 'Brasil',
    pesticide: 'Baci-Attack',
    agronomic_category: 'control_method.biological',
    asset_owner: 'Vittia',
    pesticide_unit: 'product_unit_measure.l',
    formulation: 'pesticide_formulation.concentrated_suspension_sc',
    pesticide_id: 1065,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.biological',
        mode_of_action: 'pesticide_mode_of_action.unknown',
        active_ingredient: 'active_ingredient.bacillus_subtilis',
        active_ingredient_concentration: 7,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.in_furrow',
          dosis_unit: 'product_unit_measure.l_ha',
          dosis_min: 1,
          dosis_max: 6,
        },
      ],
    ],
  },
  {
    country: 'Brasil',
    pesticide: 'Baci-Guard',
    agronomic_category: 'control_method.biological',
    asset_owner: 'Vittia',
    pesticide_unit: 'product_unit_measure.l',
    formulation: 'pesticide_formulation.concentrated_suspension_sc',
    pesticide_id: 1101,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.biological',
        mode_of_action: 'pesticide_mode_of_action.unknown',
        active_ingredient: 'active_ingredient.bacillus_subtilis',
        active_ingredient_concentration: 7,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.in_furrow',
          dosis_unit: 'product_unit_measure.l_ha',
          dosis_min: 1,
          dosis_max: 6,
        },
      ],
    ],
  },
  {
    country: 'Brasil',
    pesticide: 'Biobac',
    agronomic_category: 'control_method.biological',
    asset_owner: 'UPL',
    pesticide_unit: 'product_unit_measure.kg',
    formulation: 'pesticide_formulation.wettable_powder_wp',
    pesticide_id: 1092,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.biological',
        mode_of_action: 'pesticide_mode_of_action.unknown',
        active_ingredient: 'active_ingredient.bacillus_subtilis',
        active_ingredient_concentration: 500,
        active_ingredient_unit: 'product_unit_measure.g_kg',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.in_furrow',
          dosis_unit: 'product_unit_measure.kg_ha',
          dosis_min: 2,
          dosis_max: 4,
        },
      ],
    ],
  },
  {
    country: 'Brasil',
    pesticide: 'Biobaci',
    agronomic_category: 'control_method.biological',
    asset_owner: 'Vittia',
    pesticide_unit: 'product_unit_measure.l',
    formulation: 'pesticide_formulation.concentrated_suspension_sc',
    pesticide_id: 1117,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.biological',
        mode_of_action: 'pesticide_mode_of_action.unknown',
        active_ingredient: 'active_ingredient.bacillus_subtilis',
        active_ingredient_concentration: 7,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.in_furrow',
          dosis_unit: 'product_unit_measure.l_ha',
          dosis_min: 1,
          dosis_max: 6,
        },
      ],
    ],
  },
  {
    country: 'Brasil',
    pesticide: 'Boneville',
    agronomic_category: 'control_method.biological',
    asset_owner: 'Koppert do Brasil Holding',
    pesticide_unit: 'product_unit_measure.kg',
    formulation: 'pesticide_formulation.wettable_powder_wp',
    pesticide_id: 1059,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.biological',
        mode_of_action: 'pesticide_mode_of_action.unknown',
        active_ingredient: 'active_ingredient.bacillus_amyloliquefaciens',
        active_ingredient_concentration: 50,
        active_ingredient_unit: 'product_unit_measure.g_kg',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.seed_treatment',
          dosis_unit: 'product_unit_measure.kg_100_kg_de_semente',
          dosis_min: 0.03,
          dosis_max: 0.4,
        },
      ],
    ],
  },
  {
    country: 'Brasil',
    pesticide: 'Captor',
    agronomic_category: 'control_method.chemical',
    asset_owner: 'ALTA - America Latina Tecnologia Agr\u00edcola',
    pesticide_unit: 'product_unit_measure.l',
    formulation: 'pesticide_formulation.concentrated_suspension_sc',
    pesticide_id: 1118,
    activeIngredients: [
      {
        chemical_group: 'chemical_group.oxime_methylcarbamate',
        mode_of_action:
          'pesticide_mode_of_action.systemic_and_contact_insecticide',
        active_ingredient: 'active_ingredient.thiodicarb',
        active_ingredient_concentration: 350,
        active_ingredient_unit: 'product_unit_measure.g_l',
      },
    ],
    operations: [
      [
        {
          operation_type: 'operation_type.in_furrow',
          dosis_unit: 'product_unit_measure.l_ha',
          dosis_min: 0.8,
          dosis_max: 1,
        },
      ],
    ],
  },
]

const catalogVarieties = [
  {
    seed_id: 1227,
    name: '5D6215 IPRO',
    asset_owner: 'Brevant',
    general_info: {
      relative_maturity: '6.4',
      growth_habit: 'growth_habit.indeterminate',
      min_cycle: 130,
      max_cycle: 134,
      rust_tolerant: false,
      min_population: 200,
      max_population: 300,
      start_sowing_format: '20/09',
      end_sowing_format: '31/12',
      ogm: 'Yes',
    },
  },
  {
    seed_id: 263,
    name: '5D634 RR',
    asset_owner: 'Dow AgroSciences',
    general_info: {
      relative_maturity: '6.3',
      growth_habit: 'growth_habit.indeterminate',
      min_cycle: 134,
      max_cycle: 138,
      rust_tolerant: false,
      min_population: 220,
      max_population: 300,
      start_sowing_format: '01/10',
      end_sowing_format: '15/12',
      ogm: 'Yes',
    },
  },
  {
    seed_id: 1849,
    name: '95R21 E',
    asset_owner: 'Pioneer',
    general_info: {
      relative_maturity: '5.2',
      growth_habit: 'growth_habit.indeterminate',
      min_cycle: 113,
      max_cycle: 117,
      rust_tolerant: false,
      min_population: 340,
      max_population: 360,
      start_sowing_format: '01/10',
      end_sowing_format: '15/12',
      ogm: 'Yes',
    },
  },
  {
    seed_id: 1343,
    name: '95R30 IPRO',
    asset_owner: 'Pioneer',
    general_info: {
      relative_maturity: '5.0',
      growth_habit: 'growth_habit.indeterminate',
      min_cycle: 121,
      max_cycle: 125,
      rust_tolerant: false,
      min_population: 280,
      max_population: 300,
      start_sowing_format: '20/09',
      end_sowing_format: '10/12',
      ogm: 'Yes',
    },
  },
  {
    seed_id: 1307,
    name: '95R40 IPRO',
    asset_owner: 'Pioneer',
    general_info: {
      relative_maturity: '5.4',
      growth_habit: 'growth_habit.indeterminate',
      min_cycle: 120,
      max_cycle: 124,
      rust_tolerant: false,
      min_population: 250,
      max_population: 350,
      start_sowing_format: '01/10',
      end_sowing_format: '15/12',
      ogm: 'Yes',
    },
  },
  {
    seed_id: 11,
    name: '95R51',
    asset_owner: 'Pioneer',
    general_info: {
      relative_maturity: '5.3',
      growth_habit: 'growth_habit.indeterminate',
      min_cycle: 118,
      max_cycle: 122,
      rust_tolerant: false,
      min_population: 320,
      max_population: 390,
      start_sowing_format: '15/09',
      end_sowing_format: '15/12',
      ogm: 'Yes',
    },
  },
  {
    seed_id: 3332,
    name: '95R70 CE',
    asset_owner: 'Pioneer',
    general_info: {
      relative_maturity: '5.7',
      growth_habit: 'growth_habit.indeterminate',
      min_cycle: 126,
      max_cycle: 130,
      rust_tolerant: false,
      min_population: 220,
      max_population: 260,
      start_sowing_format: '15/09',
      end_sowing_format: '15/12',
      ogm: 'Yes',
    },
  },
  {
    seed_id: 1186,
    name: '95R90 IPRO',
    asset_owner: 'Pioneer',
    general_info: {
      relative_maturity: '5.9',
      growth_habit: 'growth_habit.indeterminate',
      min_cycle: 132,
      max_cycle: 136,
      rust_tolerant: false,
      min_population: 250,
      max_population: 320,
      start_sowing_format: '15/09',
      end_sowing_format: '15/12',
      ogm: 'Yes',
    },
  },
  {
    seed_id: 1187,
    name: '95R95 IPRO',
    asset_owner: 'Pioneer',
    general_info: {
      relative_maturity: '5.9',
      growth_habit: 'growth_habit.indeterminate',
      min_cycle: 132,
      max_cycle: 136,
      rust_tolerant: false,
      min_population: 250,
      max_population: 320,
      start_sowing_format: '15/09',
      end_sowing_format: '15/12',
      ogm: 'Yes',
    },
  },
  {
    seed_id: 1304,
    name: '95Y02 IPRO',
    asset_owner: 'Pioneer',
    general_info: {
      relative_maturity: '4.9',
      growth_habit: 'growth_habit.indeterminate',
      min_cycle: 116,
      max_cycle: 120,
      rust_tolerant: false,
      min_population: 300,
      max_population: 340,
      start_sowing_format: '15/09',
      end_sowing_format: '31/12',
      ogm: 'Yes',
    },
  },
  {
    seed_id: 1847,
    name: '95Y42 IPRO',
    asset_owner: 'Pioneer',
    general_info: {
      relative_maturity: '5.4',
      growth_habit: 'growth_habit.indeterminate',
      min_cycle: 123,
      max_cycle: 127,
      rust_tolerant: false,
      min_population: 260,
      max_population: 300,
      start_sowing_format: '20/10',
      end_sowing_format: '30/11',
      ogm: 'Yes',
    },
  },
  {
    seed_id: 1191,
    name: '95Y52',
    asset_owner: 'Pioneer',
    general_info: {
      relative_maturity: '5.5',
      growth_habit: 'growth_habit.indeterminate',
      min_cycle: 120,
      max_cycle: 124,
      rust_tolerant: false,
      min_population: 340,
      max_population: 370,
      start_sowing_format: '15/09',
      end_sowing_format: '21/12',
      ogm: 'Yes',
    },
  },
  {
    seed_id: 14,
    name: '95Y72',
    asset_owner: 'Pioneer',
    general_info: {
      relative_maturity: '4.9',
      growth_habit: 'growth_habit.indeterminate',
      min_cycle: 118,
      max_cycle: 122,
      rust_tolerant: false,
      min_population: 340,
      max_population: 370,
      start_sowing_format: '15/09',
      end_sowing_format: '15/12',
      ogm: 'Yes',
    },
  },
  {
    seed_id: 1339,
    name: '96R10 IPRO',
    asset_owner: 'Pioneer',
    general_info: {
      relative_maturity: '6.1',
      growth_habit: 'growth_habit.indeterminate',
      min_cycle: 133,
      max_cycle: 137,
      rust_tolerant: false,
      min_population: 190,
      max_population: 210,
      start_sowing_format: '01/10',
      end_sowing_format: '10/12',
      ogm: 'Yes',
    },
  },
  {
    seed_id: 1306,
    name: '96R29 IPRO',
    asset_owner: 'Pioneer',
    general_info: {
      relative_maturity: '6.2',
      growth_habit: 'growth_habit.indeterminate',
      min_cycle: 129,
      max_cycle: 133,
      rust_tolerant: false,
      min_population: 200,
      max_population: 300,
      start_sowing_format: '01/10',
      end_sowing_format: '10/12',
      ogm: 'Yes',
    },
  },
]

export { catalogFungicides, catalogNematicides, catalogVarieties }
