export default {
  ComingSoon: {
    clickedLikeButton: 'Catalog_soon_like',
    clickedUnlikeButton: 'Catalog_soon_unlike',

    selectedMenu: (menu) => `menu_${menu}`,
    getInTouch: (menu) => `menu_${menu}`,
  },

  visitedPage: 'visitedPage_Catalogs',

  clicked_variety: 'clickedTab_Genetics',
  clicked_fungicide: 'clickedTab_Fungicides',
  clicked_nematicide: 'clickedTab_Nematicides',
}
