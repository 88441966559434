export default {
  Comparison: {
    title: 'Comparar programa',
    back: 'Voltar para planejamento',
    header: {
      title: 'Planejamento',
      subtitle: 'Comparar programas',
    },
  },
}
