export default {
  Comparison: {
    title: 'Program comparison',
    back: 'Back to planning',
    header: {
      title: 'Planning',
      subtitle: 'Compare programs',
    },
  },
}
