import api from '@/services/api'
import { prepareArrayFilter, prepareSingleFilter } from './utils/query'

export default {
  namespaced: true,

  state: {
    catalog: {
      selectedItem: null,
    },
    catalogItemsCount: 0,
    catalogItems: [],
    hasMore: false,
    catalogCategory: null,
    loadingFilterData: false,
    filter: {
      options: {
        sort_select: [],
        extra_filter: [],
      },
      selectedOptions: {},
      selectedSortItem: '',
      query: '',
      isLoading: false,
    },
  },

  mutations: {
    SET_CATALOG_CATEGORY(state, catalogCategory) {
      state.catalogCategory = catalogCategory
    },
    SET_CATALOG_ITEMS(state, items) {
      state.catalogItems = items
    },
    SET_CATALOG_SELECTED_ITEM(state, item) {
      state.catalog.selectedItem = item
    },
    SET_LOADING_FILTER_DATA(state, isLoadingData) {
      state.loadingFilterData = isLoadingData
    },
    SET_LOADING_FILTER_OPTIONS(state, isLoadingFilterOptions) {
      state.filter.isLoading = isLoadingFilterOptions
    },
    SET_FILTER_SELECTED_OPTIONS(state, selectedOptions) {
      state.filter.selectedOptions = selectedOptions
    },
    SET_FILTER_SELECTED_SORT_ITEM(state, selectedSortItem) {
      state.filter.selectedSortItem = selectedSortItem
    },
    SET_FILTER_QUERY(state, queryString) {
      state.filter.query = queryString
    },
    SET_FILTER_EXTRA_OPTIONS(state, data) {
      state.filter.options.extra_filter = data?.extra_filter || []
    },
    SET_FILTER_SORT_OPTIONS(state, data) {
      state.filter.options.sort_select = data?.sort_select || []
    },
    RESET_SELECTED_ITEM(state) {
      state.catalog = {
        selectedItem: null,
      }
    },
    RESET_FILTER(state) {
      state.filter.selectedOptions = {}
      state.filter.selectedSortItem = ''
      state.filter.query = ''
    },
    SET_HAS_MORE(state, data) {
      state.hasMore = data
    },
    SET_CATALOG_ITEMS_TOTAL(state, data) {
      state.catalogItemsCount = data
    },
  },

  getters: {
    catalogItems: (state) => {
      return state.catalogItems
    },
    catalogItemsCount: (state) => {
      return state.catalogItemsCount
    },
    catalogSelectedItem: (state) => {
      return state.catalog.selectedItem
    },
    catalogCategory: (state) => {
      return state.catalogCategory
    },
    loadingFilterData: (state) => {
      return state.loadingFilterData
    },
    loadingFilterOptions: (state) => {
      return state.filter.isLoading
    },
    selectedOptions: (state) => {
      return state.filter.selectedOptions
    },
    sortItems: (state) => {
      return state.filter.options.sort_select
    },
    filters: (state) => {
      return state.filter.options.extra_filter
    },
    hasMore: (state) => {
      return state.hasMore
    },
  },

  actions: {
    async resetCatalogItems({ commit }) {
      commit('SET_CATALOG_ITEMS', [])
    },

    async setCatalogSelectedItem({ commit }, item) {
      commit('SET_CATALOG_SELECTED_ITEM', item)
    },

    async setCatalogCategory({ commit }, catalogCategory) {
      commit('SET_CATALOG_CATEGORY', catalogCategory)
    },

    async setSelectedOptions({ commit, state }, payload) {
      const { section, selectedSectionOption } = payload
      let changedOptions = {
        ...state.filter.selectedOptions,
        [section]: selectedSectionOption,
      }

      if (!selectedSectionOption) {
        delete changedOptions[section]
      }
      commit('SET_FILTER_SELECTED_OPTIONS', changedOptions)
    },

    async setSelectedSortItem({ commit }, payload) {
      commit('SET_FILTER_SELECTED_SORT_ITEM', payload)
    },

    async resetFilter({ commit }) {
      await commit('RESET_FILTER')
    },
    async resetSelectedItem({ commit }) {
      commit('RESET_SELECTED_ITEM')
    },

    async fetchFilters({ commit, dispatch, rootState, state }) {
      try {
        commit('SET_LOADING_FILTER_OPTIONS', true)
        const catalogCategory = rootState.catalog.catalogCategory
        const crop_id = rootState.crops.currentCrop.id || 1

        await dispatch('prepareFiltersForQuery', true)

        const farmLocation = rootState.farms?.currentFarm?.location
        const account = rootState.user.user.account
        const location = {
          country_id: farmLocation?.country?.id ?? account.country_id,
          state_id: farmLocation?.state?.id ?? account.state_id,
          city_id: farmLocation?.city?.id ?? account.city_id,
        }

        const { data } = await api.catalog.getFilters(
          catalogCategory,
          crop_id,
          location,
          state.filter.query
        )

        commit('SET_FILTER_SORT_OPTIONS', data)
        commit('SET_FILTER_EXTRA_OPTIONS', data)
        commit('SET_LOADING_FILTER_OPTIONS', false)
      } catch (error) {
        commit('SET_LOADING_FILTER_OPTIONS', false)
      }
    },

    async fetchCatalogList({ commit, dispatch, state, rootState }, offset = 1) {
      try {
        if (!state.catalogCategory) return

        commit('RESET_SELECTED_ITEM')
        commit('SET_LOADING_FILTER_DATA', true)

        if (offset == 1) {
          commit('SET_CATALOG_ITEMS', [])
        }

        await dispatch('prepareFiltersForQuery')

        const farmLocation = rootState.farms?.currentFarm?.location
        const account = rootState.user.user.account
        const location = {
          country_id: farmLocation?.country?.id ?? account.country_id,
          state_id: farmLocation?.state?.id ?? account.state_id,
          city_id: farmLocation?.city?.id ?? account.city_id,
        }

        const { data } = await api.catalog.get(
          state.catalogCategory,
          rootState.crops.currentCrop?.id,
          location,
          state.filter.query,
          offset
        )

        const paginatedList = data.list

        const updatedItems =
          offset > 1 ? [...state.catalogItems, ...paginatedList] : paginatedList

        commit(
          'SET_HAS_MORE',
          paginatedList?.length > 0 && paginatedList?.length == 50
        )
        commit('SET_CATALOG_ITEMS_TOTAL', data.total)
        commit('SET_CATALOG_ITEMS', updatedItems)

        commit('SET_LOADING_FILTER_DATA', false)
      } catch (error) {
        commit('SET_LOADING_FILTER_DATA', false)
      }
    },

    prepareFiltersForQuery({ commit, state }, isFilterQuery = false) {
      const queryString = state.filter.selectedOptions
        ? Object.entries(state.filter.selectedOptions)
            .map(([section, value]) => {
              if (Array.isArray(value) && value.length > 0) {
                return prepareArrayFilter(section, value)
              } else if (value !== undefined && value !== null) {
                return prepareSingleFilter(section, value, isFilterQuery)
              } else {
                return ''
              }
            })
            .filter((param) => param !== '')
        : []

      if (
        state.filter?.selectedSortItem &&
        !state.filter?.selectedSortItem?.startsWith('gmr')
      ) {
        queryString.push(`sort=${state.filter.selectedSortItem}`)
      }

      commit('SET_FILTER_QUERY', queryString.join('&'))
    },
  },
}
