import catalog_br from './catalog_br'

export default {
  Catalog: {
    Home: {
      title: 'Catálogo',
      subtitle: 'Planejamento',
    },
    ComingSoon: {
      label: 'Em breve',
      title: 'Catálogo dos seus insumos agrícolas',
      description:
        'Simplificamos o acesso a dados vitais para agronomia através de um catálogo completo dos principais insumos agrícolas, bulas de produtos e informações para a tomada de decisão do produtor, economizando tempo e esforço.',
      Buttons: {
        like: 'Gostei',
        dislike: 'Não gostei',
      },
      Toast: {
        thanksForFeedback: 'Obrigado pelo seu feedback!',
      },
      Messages: {
        feedbackCongratulations: 'Obrigado pelo seu feedback! 🎉',
      },
    },
    Categories: {
      fungicide: 'Fungicidas',
      nematicide: 'Nematicidas',
      variety: 'Cultivares',
    },
    FilterBar: {
      name: 'Nome do produto',
      order: 'Ordenar',
      filter: 'Filtrar',
      Crop: {
        soybean: 'Soja',
        wheat: 'Trigo',
      },
    },
    asset_owner: 'Empresa',
    owner_search: 'Pesquisar nome da empresa',
    target: 'Alvo',
    to: 'a',
    chemical_group: 'Grupo químico',
    active_ingredient: 'Ingrediente ativo',
    crop_cycle: 'Ciclo aproximado',
    gmr: 'GMR',
    sowing_time: 'Época de semeadura',
    no_selected: 'Selecione um item para ver informações detalhadas',
    general_information: 'Informações gerais',
    action_mode: 'Mode de ação',
    agronomic_category: 'Categoria agronômica',
    operation_type: 'Uso',
    active_ingredient_info: 'Ingrediente ativo (concentração)',
    measure: 'Medida',
    formulation: 'Formulação',
    information_variety: 'Informações | geolocalizada',
    habit: 'Hábito de Crescimento',
    approximate_cycle: 'Ciclo aproximado',
    suggested_population: 'População sugerida',
    ogm: 'OGM - Organismo geneticamente modificado',
    population_suggestion: 'mil plantas/ha',
    dosis_target: 'Doses por alvo parte aérea',
    dosis_by_target_values: '{0}L à {1}L',
    Options: {
      Sort: {
        name_a_z: 'Nome (A - Z)',
        name_z_a: 'Nome (Z - A)',
        company_a_z: 'Empresa (A - Z)',
        company_z_a: 'Empresa (Z - A)',
        gmr_less_greater: 'GMR (Crescente)',
        gmr_greater_less: 'GMR (Descrescente)',
      },
      soybean_asian_soybean_rust: 'Ferrugem',
      soybean_anthracnosis: 'Antracnose',
      soybean_target_spot: 'Mancha Alvo',
      soybean_powdery_mildew: 'Oídio',
      soybean_cercospora_blight: 'Cercóspora',
      soybean_septoria_leaf_spot: 'Septoriose',
      soybean_aerial_web_blight: 'Mela',
      soybean_white_mold: 'Mofo Branco',
      soybean_frogeye_leaf_spot: 'Mancha olho de rã',
      soybean_diaporthe: 'Crestamento de vagem e haste',
      wheat_leaf_rust: 'Ferrugem',
      wheat_powdery_mildew: 'Oídio',
      wheat_yellow_leaf_spot: 'Mancha Amarela',
      wheat_fusarium_head_blight: 'Giberela',
      wheat_blast: 'Brusone',
      ...catalog_br,
    },
  },
}
