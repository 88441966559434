export default {
  phytosanitary_category: {
    diseases: 'Diseases',
    insects: 'Insects',
    nematodes: 'Nematodes',
    weeds: 'Weeds',
  },
  genetics_category: {
    variety: 'Variety',
    hybrids: 'Hybrids',
    varieties: 'Varieties',
  },
  plant_nutrition_category: {
    correction_k: 'Correction K',
    correction_p: 'Correction P',
    correction_ph: 'Correction pH',
    cover_crops: 'Cover Crops',
    manutention_k: 'Manutention K',
    manutention_n: 'Manutention N',
    manutention_p: 'Manutention P',
    organic_manure: 'Organic Manure',
    plant_nutrition: 'Plant Nutrition',
    resistance_activation: 'Resistance Activation',
    sowing_k: 'Sowing K',
    sowing_n: 'Sowing N',
    sowing_p: 'Sowing P',
    yield_activation: 'Yield Activation',
  },
  operation_type: {
    aerial_spray: 'Aerial Spray',
    correction_fertilization: 'Correction Fertilization',
    desiccation_pre_harvest: 'Desiccation Pre Harvest',
    early_emerging_post: 'Early Emerging Post',
    foliar_fertilization: 'Foliar Fertilization',
    harvest: 'Harvest',
    in_furrow: 'In-Furrow',
    late_emerging_post: 'Late Emerging Post',
    maintenance_fertilization: 'Maintenance Fertilization',
    organic_fertilizer: 'Organic Fertilizer',
    pre_emerging: 'Pre Emerging',
    pre_emergent_overlay: 'Pre-Emergent Overlay',
    regular_emerging_post: 'Regular Emerging Post',
    seed_treatment: 'Seed Treatment',
    sequential_desiccation_1: 'Sequential Desiccation 1',
    sequential_desiccation_2: 'Sequential Desiccation 2',
    sowing: 'Sowing',
    spray_on_bare_ground: 'Spray on Bare Ground',
  },
  crop: {
    fallow: 'Fallow',
    others: 'Others',
    alfalfa: 'Alfalfa',
    barley: 'Barley',
    bean: 'Bean',
    black_oats: 'Black Oats',
    brachiaria: 'Brachiaria',
    brachiaria_bizantha: 'Brachiaria-Bizantha',
    brachiaria_decumbens: 'Brachiaria-Decumbens',
    brachiaria_riziziensis: 'Brachiaria-Riziziensis',
    canola: 'Canola',
    corn: 'Corn',
    cotton: 'Cotton',
    cowpea: 'Cowpea',
    crotalaria: 'Crotalaria',
    crotalaria_breviflora: 'Crotalaria-Breviflora',
    crotalaria_juncea: 'Crotalaria-Juncea',
    crotalaria_ochroleuca: 'Crotalaria-Ochroleuca',
    crotalaria_spectabilis: 'Crotalaria-Spectabilis',
    dry_pea: 'Dry Pea',
    flax: 'Flax',
    forage_pea: 'Forage Pea',
    forage_sorghum: 'Forage Sorghum',
    grain_sorghum: 'Grain Sorghum',
    irrigated_rice: 'Irrigated Rice',
    lupin: 'Lupin',
    peanut: 'Peanut',
    pearl_millet: 'Pearl Millet',
    popcorn: 'Popcorn',
    rye: 'Rye',
    ryegrass: 'Ryegrass',
    soybean: 'Soybean',
    sugarcane: 'Sugarcane',
    sunflower: 'Sunflower',
    triticale: 'Triticale',
    turnip: 'Turnip',
    upland_rice: 'Upland Rice',
    velvet_bean: 'Velvet Bean',
    vetch: 'Vetch',
    wheat: 'Wheat',
    white_oats: 'White Oats',
    yucca: 'Yucca',
  },
  control_method: {
    biochemical: 'Biochemical',
    biological: 'Biological',
    chemical: 'Chemical',
  },
  pesticide_class: {
    biochemicals_with_multiple_modes_of_action:
      'Biochemicals with Multiple Modes of Action',
    biologicals_with_multiple_modes_of_action:
      'Biologicals with Multiple Modes of Action',
    fungicide: 'Fungicide',
    herbicide: 'Herbicide',
    insecticide: 'Insecticide',
    nematicide: 'Nematicide',
    plant_extract: 'Plant Extract',
    plant_resistance_activator: 'Plant Resistance Activator',
    plant_yield_activator: 'Plant Yield Activator',
    unknown: 'Unknown',
  },
  pesticide_mode_of_action: {
    contact_and_ingestion_insecticide: 'Contact and Ingestion Insecticide',
    contact_insecticide: 'Contact Insecticide',
    ingestion_insecticide: 'Ingestion Insecticide',
    inorganic_multi_site_fungicide: 'Inorganic Multi-Site Fungicide',
    multi_site_fungicide: 'Multi-Site Fungicide',
    organic_multi_site_fungicide: 'Organic Multi-Site Fungicide',
    site_specific_fungicide: 'Site-Specific Fungicide',
    systemic_and_contact_insecticide: 'Systemic and Contact Insecticide',
    systemic_insecticide: 'Systemic Insecticide',
    systemic_nematicide: 'Systemic Nematicide',
    unknown: 'Unknown',
  },
  chemical_group: {
    acylalaninate: 'Acylalaninate',
    amino_pyrazolinones: 'Amino Pyrazolinones',
    anilinopyrimidine: 'Anilinopyrimidine',
    avermectin: 'Avermectin',
    benzamide: 'Benzamide',
    benzimidazole: 'Benzimidazole',
    benzofuranyl_methylcarbamate: 'Benzofuranyl Methylcarbamate',
    benzothiodiazole: 'Benzothiodiazole',
    biochemical: 'Biochemical',
    biological: 'Biological',
    carboxamide: 'Carboxamide',
    cyanoacetamide_oxime: 'Cyanoacetamide Oxime',
    dicarboximide: 'Dicarboximide',
    dithiocarbamate: 'Dithiocarbamate',
    fluoroalkenyl: 'Fluoroalkenyl',
    inorganic: 'Inorganic',
    isophthalonitrile: 'Isophthalonitrile',
    morpholine: 'Morpholine',
    neonicotinoid: 'Neonicotinoid',
    organophosphate: 'Organophosphate',
    others: 'Others',
    oxime_methylcarbamate: 'Oxime Methylcarbamate',
    phenyl_oxo_ethyl_thiophene_amide: 'Phenyl Oxo Ethyl Thiophene Amide',
    phenylpyridinylamine: 'Phenylpyridinylamine',
    phenylpyrrole: 'Phenylpyrrole',
    plant_extract: 'Plant Extract',
    pyrimidine: 'Pyrimidine',
    quaternary_ammonium: 'Quaternary Ammonium',
    strobilurin: 'Strobilurin',
    triazole: 'Triazole',
    triazolinthione: 'Triazolinthione',
  },
  active_ingredient: {
    abamectin: 'Abamectin',
    acibenzolar_s_methyl: 'Acibenzolar-S-Methyl',
    allium_sativum: 'Allium Sativum',
    azoxystrobin: 'Azoxystrobin',
    bacillus_amyloliquefaciens: 'Bacillus Amyloliquefaciens',
    bacillus_firmus: 'Bacillus Firmus',
    bacillus_licheniformis: 'Bacillus Licheniformis',
    bacillus_methylotrophicus: 'Bacillus Methylotrophicus',
    bacillus_paralicheniformis: 'Bacillus Paralicheniformis',
    bacillus_pumilus: 'Bacillus Pumilus',
    bacillus_subtilis: 'Bacillus Subtilis',
    bacillus_velezensis: 'Bacillus Velezensis',
    benzalkonium_chloride: 'Benzalkonium Chloride',
    benzovindiflupyr: 'Benzovindiflupyr',
    bixafen: 'Bixafen',
    boscalid: 'Boscalid',
    bromuconazole: 'Bromuconazole',
    cadusafos: 'Cadusafos',
    captan: 'Captan',
    carbendazim: 'Carbendazim',
    carbofuran: 'Carbofuran',
    carbosulfan: 'Carbosulfan',
    carboxin: 'Carboxin',
    cerevisane: 'Cerevisane',
    chlorothalonil: 'Chlorothalonil',
    copper_hydroxide: 'Copper Hydroxide',
    copper_oxide: 'Copper Oxide',
    copper_oxychloride: 'Copper Oxychloride',
    cyclobutrifluram: 'Cyclobutrifluram',
    cymoxanil: 'Cymoxanil',
    cyproconazole: 'Cyproconazole',
    cyprodinil: 'Cyprodinil',
    difenoconazole: 'Difenoconazole',
    dimoxystrobin: 'Dimoxystrobin',
    epoxiconazole: 'Epoxiconazole',
    fatty_acids: 'Fatty Acids',
    fenamifos: 'Fenamifos',
    fenarimol: 'Fenarimol',
    fenpropidin: 'Fenpropidin',
    fenpropimorph: 'Fenpropimorph',
    fenpyrazamine: 'Fenpyrazamine',
    fluazinam: 'Fluazinam',
    fludioxonil: 'Fludioxonil',
    fluensulfona: 'Fluensulfona',
    fluindapyr: 'Fluindapyr',
    fluopyram: 'Fluopyram',
    fluoxastrobin: 'Fluoxastrobin',
    fluquinconazole: 'Fluquinconazole',
    flutriafol: 'Flutriafol',
    fluxapyroxad: 'Fluxapyroxad',
    imidacloprid: 'Imidacloprid',
    inpyrfluxam: 'Inpyrfluxam',
    ipconazole: 'Ipconazole',
    iprodione: 'Iprodione',
    isofetamid: 'Isofetamid',
    kresoxim_methyl: 'Kresoxim-Methyl',
    mancozeb: 'Mancozeb',
    mefentrifluconazole: 'Mefentrifluconazole',
    melaleuca_alternifolia: 'Melaleuca Alternifolia',
    metalaxyl_m: 'Metalaxyl-M',
    metconazole: 'Metconazole',
    metiram: 'Metiram',
    metominostrobin: 'Metominostrobin',
    mineral_extract: 'Mineral Extract',
    myclobutanil: 'Myclobutanil',
    orange_vegetable_oil: 'Orange Vegetable Oil',
    others: 'Others',
    paecilomyces_lilacinus: 'Paecilomyces Lilacinus',
    pasteuria_nishizawae: 'Pasteuria Nishizawae',
    picoxystrobin: 'Picoxystrobin',
    plant_extract: 'Plant Extract',
    pochonia_chlamydosporia: 'Pochonia Chlamydosporia',
    procymidone: 'Procymidone',
    propiconazole: 'Propiconazole',
    propineb: 'Propineb',
    prothioconazole: 'Prothioconazole',
    purpureocillium_lilacinum: 'Purpureocillium Lilacinum',
    pydiflumetofen: 'Pydiflumetofen',
    pyraclostrobin: 'Pyraclostrobin',
    sulfur: 'Sulfur',
    tebuconazole: 'Tebuconazole',
    terbufos: 'Terbufos',
    tetraconazole: 'Tetraconazole',
    thiabendazole: 'Thiabendazole',
    thiodicarb: 'Thiodicarb',
    thiophanate_methyl: 'Thiophanate-Methyl',
    thiram: 'Thiram',
    triadimefon: 'Triadimefon',
    trichoderma_afroharzianum: 'Trichoderma Afroharzianum',
    trichoderma_asperellum: 'Trichoderma Asperellum',
    trichoderma_endophyticum: 'Trichoderma Endophyticum',
    trichoderma_harzianum: 'Trichoderma Harzianum',
    tricyclazole: 'Tricyclazole',
    trifloxystrobin: 'Trifloxystrobin',
  },
  pesticide_formulation: {
    encapsulated_suspension_cs: 'Encapsulated Suspension (CS)',
    dispersible_concentrate_dc: 'Dispersible Concentrate (DC)',
    emulsifiable_concentrate_ec: 'Emulsifiable Concentrate (EC)',
    water_in_oil_emulsion_eo: 'Water-in-Oil Emulsion (EO)',
    oil_in_water_emulsion_ew: 'Oil-in-Water Emulsion (EW)',
    microemulsion_me: 'Microemulsion (ME)',
    concentrated_suspension_sc: 'Concentrated Suspension (SC)',
    suspo_emulsion_se: 'Suspo-Emulsion (SE)',
    soluble_granules_sg: 'Soluble Granules (SG)',
    soluble_concentrate_sl: 'Soluble Concentrate (SL)',
    soluble_powder_sp: 'Soluble Powder (SP)',
    tablet_tb: 'Tablet (TB)',
    tablet_for_direct_application_dt: 'Tablet for Direct Application (DT)',
    tablet_for_dissolution_in_water_st: 'Tablet for Dissolution in Water (ST)',
    tablet_for_dispersion_in_water_wt: 'Tablet for Dispersion in Water (WT)',
    dispersible_granules_wg: 'Dispersible Granules (WG)',
    wettable_powder_wp: 'Wettable Powder (WP)',
    block_br: 'Block (BR)',
    gelou_concentrate_paste_pc: 'Gelou Concentrate Paste (PC)',
    emulsifiable_gel_gl: 'Emulsifiable Gel (GL)',
    water_soluble_gel_gw: 'Water Soluble Gel (GW)',
    emulsifiable_granule_eg: 'Emulsifiable Granule (EG)',
    emulsifiable_powder_ep: 'Emulsifiable Powder (EP)',
    oil_dispersion_or_oil_concentrated_suspension_od:
      'Oil Dispersion or Oil Concentrated Suspension (OD)',
    mixed_formulation_of_cs_and_sc_zc: 'Mixed Formulation of CS and SC (ZC)',
    mixed_formulation_of_cs_and_se_ze: 'Mixed Formulation of CS and SE (ZE)',
    mixed_formulation_of_cs_and_ew_zw: 'Mixed Formulation of CS and EW (ZW)',
    concentrated_suspension_dispersible_or_miscible_in_oil_of:
      'Concentrated Suspension Dispersible or Miscible in Oil (OF)',
    oil_miscible_solution_oi: 'Oil Miscible Solution (OI)',
    oil_dispersible_powder_op: 'Oil Dispersible Powder (OP)',
    encapsulated_granules_cg: 'Encapsulated Granules (CG)',
    dry_powder_dp: 'Dry Powder (DP)',
    liquid_for_electrostatic_electrodynamic_spraying_ed:
      'Liquid for Electrostatic/Electrodynamic Spraying (ED)',
    granules_gr: 'Granules (GR)',
    oil_for_spraying_spreading_so: 'Oil for Spraying/Spreading (SO)',
    ultra_low_volume_suspension_su: 'Ultra Low Volume Suspension (SU)',
    ultra_low_volume_ul: 'Ultra Low Volume (UL)',
    microgranulate_mg: 'Microgranulate (MG)',
    fine_powder_gp: 'Fine Powder (GP)',
    fine_granulated_fg: 'Fine Granulated (FG)',
    macrogranulate_gg: 'Macrogranulate (GG)',
    contact_powder_cp: 'Contact Powder (CP)',
    liquid_or_contact_gel_cl: 'Liquid or Contact Gel (CL)',
    concentrated_suspension_for_direct_application_sd:
      'Concentrated Suspension for Direct Application (SD)',
    other_liquids_for_direct_application_al:
      'Other Liquids for Direct Application (AL)',
    other_post_ap: 'Other Post (AP)',
    dry_seed_treatment_powder_ds: 'Dry Seed Treatment Powder (DS)',
    emulsion_for_seed_treatment_es: 'Emulsion for Seed Treatment (ES)',
    concentrated_suspension_for_seed_treatment_fs:
      'Concentrated Suspension for Seed Treatment (FS)',
    solution_for_seed_treatment_ls: 'Solution for Seed Treatment (LS)',
    soluble_powder_for_seed_treatment_ss:
      'Soluble Powder for Seed Treatment (SS)',
    powder_for_oil_paste_preparation_wo:
      'Powder for Oil Paste Preparation (WO)',
    powder_for_pasting_in_water_ws: 'Powder for Pasting in Water (WS)',
    encapsulated_suspension_for_seed_treatment_cf:
      'Encapsulated Suspension for Seed Treatment (CF)',
    seed_treatment_gel_gf: 'Seed Treatment Gel (GF)',
    aerosol_ae: 'Aerosol (AE)',
    fumigant_fu: 'Fumigant (FU)',
    fumigant_tablet_fd: 'Fumigant Tablet (FD)',
    fumigant_candle_fk: 'Fumigant Candle (FK)',
    fumigant_cartridge_fp: 'Fumigant Cartridge (FP)',
    fumigant_stick_fr: 'Fumigant Stick (FR)',
    fumigant_tablet_ft: 'Fumigant Tablet (FT)',
    fumigant_granules_fw: 'Fumigant Granules (FW)',
    liquefied_gas_under_pressure_ga: 'Liquefied Gas Under Pressure (GA)',
    gas_generator_ge: 'Gas Generator (GE)',
    concentrate_for_thermal_fogging_hn: 'Concentrate for Thermal Fogging (HN)',
    concentrate_for_cold_fogging_kn: 'Concentrate for Cold Fogging (KN)',
    hairspray_la: 'Hairspray (LA)',
    vegetable_rod_pr: 'Vegetable Rod (PR)',
    folder_pa: 'Folder (PA)',
    bait_rb: 'Bait (RB)',
    bait_in_grains_ab: 'Bait in Grains (AB)',
    block_bait_bb: 'Block Bait (BB)',
    granulated_bait_cg: 'Granulated Bait (CG)',
    plate_bait_pb: 'Plate Bait (PB)',
    bait_in_shavings_5b: 'Bait in Shavings (5B)',
    steam_producer_vp: 'Steam Producer (VP)',
    oily_paste_gs: 'Oily Paste (GS)',
    formulated_bag_fb: 'Formulated Bag (FB)',
    others_xx: 'Others (XX)',
  },
  crop_cycle: {
    medium: 'Medium',
    early: 'Early',
    super_early: 'Super Early',
    late: 'Late',
    ultra_early: 'Ultra Early',
    long: 'Long',
  },
  growth_habit: {
    determinate: 'Determinate',
    semi_determinate: 'Semi-Determinate',
    indeterminate: 'Indeterminate',
  },
  resistance_plant_reaction: {
    resistant: 'Resistant',
    moderately_resistant: 'Moderately Resistant',
    intermediate: 'Intermediate',
    moderately_susceptible: 'Moderately Susceptible',
    susceptible: 'Susceptible',
    no_information: 'No Information',
  },
  product_unit_measure: {
    percentage: '%',
    seed: 'seed',
    l_100_kg_de_semente: 'L/100 kg de semente',
    l: 'L',
    lb: 'lb',
    kg_100_kg_de_semente: 'kg/100 kg de semente',
    kg: 'kg',
    g_kg_de_semente: 'g/kg de semente',
    g_ha: 'g/ha',
    ml_l: 'mL/L',
    mg_l: 'mg/L',
    fl_oz: 'fl oz',
    l_ha: 'L/ha',
    feet: 'feet',
    square_feet: 'square feet',
    l_50_kg_de_semente: 'L/50 kg de semente',
    ml_ha: 'mL/ha',
    g_l: 'g/L',
    g_kg: 'g/kg',
    oz: 'oz',
    gallon: 'gallon',
    ml_kg_de_semente: 'mL/kg de semente',
    ml_100_kg_de_semente: 'mL/100 kg de semente',
    kg_ha: 'kg/ha',
    g_100_kg_de_semente: 'g/100 kg de semente',
    miles_per_hour: 'miles per hour',
  },
}
